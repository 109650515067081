interface RequestOptions extends RequestInit {
  params?: { [key: string]: string };
}

export const useFetch = () => {
  return async (uri: string, options?: RequestOptions | undefined) => {
    const url = new URL(`${process.env.APP_BACKEND_URL}${uri}`);

    if (window.location.pathname === "/exitframe") {
      return null;
    }

    if (options?.params) {
      Object.keys(options.params).forEach((key) => {
        url.searchParams.append(key, options.params![key]);
      });
    }

    const response = await fetch(url.toString(), options);

    if (
      response.headers.get("X-Shopify-API-Request-Failure-Reauthorize") === "1"
    ) {
      const authUrlHeader = response.headers.get(
        "X-Shopify-API-Request-Failure-Reauthorize-Url",
      );
      // 使用原生跳转到授权页面
      window.location.href = authUrlHeader || `/exitframe`;

      return null;
    }

    return response;
  };
};
