import { useFetch } from "@/hooks/useFetch";

const fetch = useFetch();

export const fetchShopInfo = async () => {
  const res = await fetch("/api/shop/info");
  if (!res?.ok) {
    throw new Error("Network response was not ok");
  }
  const { data } = (await res?.json()) || {};
  return data;
};
