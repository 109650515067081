import { Card, EmptyState, Layout, Page, Button } from "@shopify/polaris";
import IconServerDown from "@/assets/icons/server-down.svg?react";
interface ErrorPageProps {
  heading?: string; // Optional error heading
  message?: string; // Optional error message
  buttonText?: string; // Optional button text
  onButtonClick?: () => void; // Optional button click handler
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  heading = "Page Not Found",
  message = "Oops! The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.",
  buttonText = "Reload Page",
  onButtonClick = () => {
    window.location.reload(); // Reload the current page
  },
}) => {
  const handleReload = () => {
    onButtonClick();
  };

  return (
    <Layout>
      <Layout.Section>
        <Card>
          <div className="flex flex-col items-center justify-center">
            <IconServerDown className="w-64 h-64 m-auto mt-12" />
            <h4 className="text-xl font-bold">{heading}</h4>
            <p className="text-sm mt-2">{message}</p>
            <div className="m-12">
              <Button onClick={handleReload} variant="primary">
                {buttonText}
              </Button>
            </div>
          </div>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export default ErrorPage;
