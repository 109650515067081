import { useFetch } from "@/hooks/useFetch";

const fetch = useFetch();

export const fetchMembershipFeature = async () => {
  const res = await fetch("/api/membership/feature");
  if (!res?.ok) {
    throw new Error("Network response was not ok");
  }
  const { data } = await res?.json();
  return data;
};

export const fetchMembershipFeatureUsage = async () => {
  const res = await fetch("/api/membership/usage");
  if (!res?.ok) {
    throw new Error("Network response was not ok");
  }
  const { data } = await res?.json();
  return data;
};
