import { isEqual } from "lodash";
import { create } from "zustand";

interface MembershipState {
  feature: any;
  usage: any;
  setFeature: (feature: any) => void;
  setUsage: (usage: any) => void;
}

export const useMembershipStore = create<MembershipState>((set) => ({
  feature: null,
  usage: null,
  setFeature: (feature) =>
    set((state) => (isEqual(state.feature, feature) ? {} : { feature })),
  setUsage: (usage) =>
    set((state) => (isEqual(state.usage, usage) ? {} : { usage })),
}));
