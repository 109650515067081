import { create } from "zustand";
interface TemplateSettings {
  subject: string;
  content: string;
  attachmentNameRule?: string;
}

type TemplateCategory = "invoice" | "packing" | "refund" | "draft";

type EmailModalType = "email" | "attachment" | null;

interface EmailContentSettings {
  generalSettings: {
    senderName: string;
    senderEmailAddress: string;
  };
  templateSettings: Record<TemplateCategory, TemplateSettings>;
}

interface EmailState {
  emailContentSettings: EmailContentSettings | null;
  tempSettings: Partial<Record<TemplateCategory, TemplateSettings>>;
  isDirty: boolean;
  activeEmailModalType: EmailModalType;
  setEmailContentSettings: (settings: EmailContentSettings) => void;
  updateEmailContentSettings: (
    partialSettings: Partial<EmailContentSettings>,
  ) => void;
  setTempSettings: (
    category: TemplateCategory,
    settings: Partial<TemplateSettings>,
  ) => void;
  setIsDirty: (isDirty: boolean) => void;
  setActiveEmailModalType: (type: EmailModalType | null) => void;
  resetTempSettings: () => void;
}

export const useEmailStore = create<EmailState>((set) => ({
  emailContentSettings: null,
  tempSettings: {},
  isDirty: false,
  activeEmailModalType: "email",

  setEmailContentSettings: (settings) =>
    set(() => {
      const newTempSettings = Object.keys(settings.templateSettings).reduce(
        (acc, category) => {
          acc[category as TemplateCategory] = {
            ...settings.templateSettings[category as TemplateCategory],
          };
          return acc;
        },
        {} as Partial<Record<TemplateCategory, TemplateSettings>>,
      );
      return {
        emailContentSettings: settings,
        tempSettings: newTempSettings,
        isDirty: false,
      };
    }),

  updateEmailContentSettings: (partialSettings) =>
    set((state) => ({
      emailContentSettings: state.emailContentSettings
        ? { ...state.emailContentSettings, ...partialSettings }
        : (partialSettings as EmailContentSettings),
      isDirty: true,
    })),

  setTempSettings: (category, settings) =>
    set((state) => {
      const newTempSettings = {
        ...state.tempSettings,
        [category]: { ...state.tempSettings[category], ...settings },
      };

      const isDirty = Object.keys(newTempSettings).some((cat) => {
        const originalSettings =
          state.emailContentSettings?.templateSettings[cat as TemplateCategory];
        const tempSettings = newTempSettings[cat as TemplateCategory];
        return (
          originalSettings?.subject !== tempSettings?.subject ||
          originalSettings?.content !== tempSettings?.content ||
          originalSettings?.attachmentNameRule !==
            tempSettings?.attachmentNameRule
        );
      });

      return {
        tempSettings: newTempSettings,
        isDirty,
      };
    }),

  setIsDirty: (isDirty) => set({ isDirty }),

  setActiveEmailModalType: (type) =>
    set({ activeEmailModalType: type ?? "email" }),

  resetTempSettings: () =>
    set((state) => ({
      tempSettings: state.emailContentSettings
        ? Object.keys(state.emailContentSettings.templateSettings).reduce(
            (acc, category) => {
              acc[category as TemplateCategory] = {
                ...state.emailContentSettings!.templateSettings[
                  category as TemplateCategory
                ],
              };
              return acc;
            },
            {} as Partial<Record<TemplateCategory, TemplateSettings>>,
          )
        : {},
      isDirty: false,
    })),
}));
