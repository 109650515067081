// 订单弹窗
export const ORDER_MODAL_ID = "order-handler-modal";

// 区别不同套餐等级的弹窗
export const PLAN_CHANGE_MODAL_ID = "plan-change-modal";

// 升级弹窗
export const PLAN_UPGRADE_MODAL_ID = "bulk-downloads-exceeded-modal";

// 下载不够弹窗
export const DOWNLOADS_EXCEEDED_MODAL_ID = "downloads-exceeded-modal";

// 变量弹窗
export const EMAIL_VARIABLES_MODAL_ID = "email-variables-modal";

// 非shopify embedded域名弹窗
export const NOT_SHOPIFY_EMBEDDED_DOMAIN_MODAL_ID =
  "not-shopify-embedded-domain-modal";
