import { create } from "zustand";
import { PLAN_UPGRADE_MODAL_ID } from "@/constants";
type ModalStore = {
  isUpgradeModalOpen: boolean;
  upgradeModalData: {
    title: string;
    message: string;
    feature: string;
  } | null;
  openUpgradeModal: (data: {
    title: string;
    message: string;
    feature: string;
  }) => void;
  closeUpgradeModal: () => void;
};

export const useModalStore = create<ModalStore>((set) => ({
  isUpgradeModalOpen: false,
  upgradeModalData: null,
  openUpgradeModal: (data) => {
    set({ isUpgradeModalOpen: true, upgradeModalData: data });
    shopify.modal.show(PLAN_UPGRADE_MODAL_ID);
  },
  closeUpgradeModal: () => {
    set({ isUpgradeModalOpen: false, upgradeModalData: null });
    shopify.modal.hide(PLAN_UPGRADE_MODAL_ID);
  },
}));
