import { create } from "zustand";
import { ShopInfo } from "@/types";

interface ShopState {
  shopInfo: ShopInfo | null;
  setShopInfo: (shopInfo: ShopInfo) => void;
}

export const useShopStore = create<ShopState>((set) => ({
  shopInfo: null,
  setShopInfo: (shopInfo: ShopInfo) => set({ shopInfo }),
}));
