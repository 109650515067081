import { isEqual } from "lodash";
import { create } from "zustand";

type SelectedPlanType = "free" | "basic" | "premium";

interface BillingState {
  subscription: any;
  showModal: boolean;
  selectedPlan: SelectedPlanType;
  subscriptionUrl: string | null;
  setSubscriptionUrl: (url: string | null) => void;
  setSubscription: (subscription: any) => void;
  setShowModal: (show: boolean) => void;
  setSelectedPlan: (plan: SelectedPlanType) => void;
}

export const useBillingStore = create<BillingState>((set) => ({
  subscription: null,
  showModal: false,
  selectedPlan: "basic",
  subscriptionUrl: null,
  setSubscriptionUrl: (url) => set({ subscriptionUrl: url }),
  setSubscription: (subscription) =>
    set((state) =>
      isEqual(state.subscription, subscription) ? {} : { subscription },
    ),
  setShowModal: (show) => set({ showModal: show }),
  setSelectedPlan: (plan) => set({ selectedPlan: plan }),
}));
