import { createContext, useContext } from "react";
import { useEmbedApp } from "@/hooks/useEmbedApp";
const ShopifyContext = createContext(false);

export const ShopifyProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isShopifyEmbed = useEmbedApp();
  return (
    <ShopifyContext.Provider value={isShopifyEmbed}>
      {children}
    </ShopifyContext.Provider>
  );
};

export const useShopifyEmbed = () => useContext(ShopifyContext);
