// @ts-nocheck
import { fetchShopInfo } from "@/services";
import { useShopStore } from "@/stores";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useShopifyEmbed } from "@/providers/ShopifyProvider";

export const useShopManagement = () => {
  const { setShopInfo } = useShopStore();
  const isShopifyEmbed = useShopifyEmbed();
  const storedShopInfo = useShopStore((state) => state.shopInfo);

  const shopInfoQuery = useQuery({
    queryKey: ["shopInfo"],
    queryFn: fetchShopInfo,
    initialData: storedShopInfo,
    enabled: isShopifyEmbed,
    select: useCallback(
      (data) => {
        if (data && data !== storedShopInfo) {
          setShopInfo(data);
        }
        return data;
      },
      [storedShopInfo, setShopInfo],
    ),
  });

  return {
    shopInfo: shopInfoQuery.data,
    isLoadingShopInfo: shopInfoQuery.isLoading,
    isErrorShopInfo: shopInfoQuery.isError,
    errorShopInfo: shopInfoQuery.error,
    refetchShopInfo: shopInfoQuery.refetch,
    isFetchingShopInfo: shopInfoQuery.isFetching,
  };
};
