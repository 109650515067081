// @ts-nocheck
import { fetchUpdateUserSettings, fetchUserSettings } from "@/services";
import { useUserStore } from "@/stores/user.store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useShopifyEmbed } from "@/providers/ShopifyProvider";
export const useUserManagement = () => {
  const queryClient = useQueryClient();
  const isShopifyEmbed = useShopifyEmbed();
  const { userSettings: storedUserSettings, setUserSettings } = useUserStore();
  const { i18n } = useTranslation();

  const updateLanguage = useCallback(
    (language: string) => {
      if (i18n.language !== language) {
        i18n.changeLanguage(language);
      }
    },
    [i18n],
  );

  const userSettingsQuery = useQuery({
    queryKey: ["userSettings"],
    queryFn: fetchUserSettings,
    initialData: storedUserSettings,
    enabled: isShopifyEmbed,
    select: useCallback(
      (data) => {
        if (data && !isEqual(data, storedUserSettings)) {
          setUserSettings(data);
          updateLanguage(data.language);
        }
        return data;
      },
      [storedUserSettings, setUserSettings, updateLanguage],
    ),
  });

  const updateUserSettingsMutation = useMutation({
    mutationFn: fetchUpdateUserSettings,
    onSuccess: (updatedData) => {
      queryClient.setQueryData(["userSettings"], updatedData);
      setUserSettings(updatedData);
      updateLanguage(updatedData.language);
    },
  });

  return {
    userSettings: userSettingsQuery.data,
    currentLanguage: i18n.language,
    isLoadingUserSettings: userSettingsQuery.isLoading,
    isErrorUserSettings: userSettingsQuery.isError,
    errorUserSettings: userSettingsQuery.error,
    updateUserSettings: updateUserSettingsMutation.mutate,
    isUpdatingUserSettings: updateUserSettingsMutation.isPending,
    updateLanguage,
  };
};
