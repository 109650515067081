import { isDevEnv } from "@/environments";
import { useEmbedApp } from "@/hooks/useEmbedApp";
import { useMembershipManagement } from "@/hooks/useMembershipManagement";
import { useShopManagement } from "@/hooks/useShopManagement";
import { useUserManagement } from "@/hooks/useUserManagement";
import AppRoutes from "@/Routes";
import useLoadingStore from "@/stores/loading.store"; // 导入 loading store
import { NavMenu } from "@shopify/app-bridge-react";
import {
  Page,
  AppProvider as PolarisProvider,
  Spinner,
} from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import translations from "@shopify/polaris/locales/en.json";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "@zuoluotv/ui/globals.css";
import React, { Suspense, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./App.css";
import ErrorPage from "./components/Error";
import { ShopifyProvider } from "./providers/ShopifyProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent = () => {
  return (
    <PolarisProvider i18n={translations}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <Suspense
            fallback={
              <div className="flex items-center justify-center min-h-screen">
                <Spinner />
              </div>
            }
          >
            <ShopifyProvider>
              <AppContent />
            </ShopifyProvider>
          </Suspense>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Router>
    </PolarisProvider>
  );
};

// AppContent 组件
const AppContent: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { isLoadingUserSettings, isErrorUserSettings } = useUserManagement();
  const { isLoadingFeature, isLoadingUsage } = useMembershipManagement();
  const isShopifyEmbed = useEmbedApp();
  const {} = useShopManagement();
  const location = useLocation(); // 获取当前路由位置
  const setLoading = useLoadingStore((state) => state.setLoading); // 获取 setLoading 方法
  const isLoading = useLoadingStore((state) => state.isLoading); // 获取全局 loading 状态

  // 检查 isLoading 相关状态的变化情况，用于调试
  const loadingState = useMemo(() => {
    return (
      isLoadingUserSettings || isLoadingFeature || isLoadingUsage || isLoading
    ); // 更新加载状态
  }, [isLoadingUserSettings, isLoadingFeature, isLoadingUsage, isLoading]);

  useEffect(() => {
    setLoading(true); // 路由变化时设置加载状态
    const timer = setTimeout(() => setLoading(false), 1000); // 模拟加载时间
    return () => clearTimeout(timer); // 清理定时器
  }, [location, setLoading]); // 依赖于路由变化

  if (isShopifyEmbed && isErrorUserSettings) {
    return (
      <Page>
        <ErrorPage
          heading="Something went wrong..."
          message="Please try again later."
        />
      </Page>
    );
  }

  return (
    <>
      {isShopifyEmbed && (
        <NavMenu>
          <a href="/app/orders" rel="orders">
            {t("NavigationMenu.orders")}
          </a>
          <a href="/app/templates" rel="templates">
            {t("NavigationMenu.templates")}
          </a>
          <a href="/app/email" rel="email">
            {t("NavigationMenu.email")}
          </a>
          <a href="/app/billing" rel="billing">
            {t("NavigationMenu.billing")}
          </a>
          <a href="/app/settings" rel="settings">
            {t("NavigationMenu.settings")}
          </a>
          {isDevEnv && (
            <>
              <a href="/debug" rel="debug">
                {t("NavigationMenu.debug")}
              </a>
              <a href="/tools/swagger" rel="swagger">
                {t("NavigationMenu.swagger")}
              </a>
            </>
          )}
        </NavMenu>
      )}
      {isLoading ? ( // 根据全局 loading 状态显示 Spinner
        <div className="flex items-center justify-center min-h-screen">
          <Spinner />
        </div>
      ) : (
        <AppRoutes /> // 直接渲染 AppRoutes
      )}
    </>
  );
};

export default App;
