import { useFetch } from "@/hooks/useFetch";

const fetch = useFetch();

export const fetchUserSettings = async () => {
  const res = await fetch("/api/user/settings");
  if (!res?.ok) {
    throw new Error("Network response was not ok");
  }
  const { data } = (await res?.json()) || {};
  return data;
};

export const fetchUpdateUserSettings = async ({
  language,
  timeFormat,
  dateFormat,
}: {
  language: string;
  timeFormat: string;
  dateFormat: string;
}) => {
  const res = await fetch("/api/user/updateSettings", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ language, timeFormat, dateFormat }),
  });
  if (!res?.ok) {
    throw new Error("Network response was not ok");
  }
  const { data } = await res.json();
  return data;
};
