import {
  fetchMembershipFeature,
  fetchMembershipFeatureUsage,
} from "@/services";
import { useMembershipStore } from "@/stores/membership.store";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useShopifyEmbed } from "@/providers/ShopifyProvider";
// 使用更宽松的类型定义
type MembershipData = Record<string, any>;

export const useMembershipManagement = () => {
  const { setFeature, setUsage } = useMembershipStore();
  const isShopifyEmbed = useShopifyEmbed();
  const storedFeature = useMembershipStore((state) => state.feature);
  const storedUsage = useMembershipStore((state) => state.usage);

  const featureQuery = useQuery({
    queryKey: ["membershipFeature"],
    queryFn: fetchMembershipFeature,
    initialData: storedFeature,
    enabled: isShopifyEmbed,
    select: useCallback(
      (data: MembershipData | undefined) => {
        if (data && data !== storedFeature) {
          setFeature(data);
        }
        return data;
      },
      [storedFeature, setFeature],
    ),
  });

  const usageQuery = useQuery({
    queryKey: ["membershipUsage"],
    queryFn: fetchMembershipFeatureUsage,
    initialData: storedUsage,
    enabled: isShopifyEmbed,
    select: useCallback(
      (data: MembershipData | undefined) => {
        if (data && data !== storedUsage) {
          setUsage(data);
        }
        return data;
      },
      [storedUsage, setUsage],
    ),
  });

  return {
    feature: featureQuery.data,
    usage: usageQuery.data,
    isLoadingFeature: featureQuery.isLoading,
    isLoadingUsage: usageQuery.isLoading,
    isErrorFeature: featureQuery.isError,
    isErrorUsage: usageQuery.isError,
    errorFeature: featureQuery.error,
    errorUsage: usageQuery.error,
    refetchFeature: featureQuery.refetch,
    refetchUsage: usageQuery.refetch,
    isFetchingFeature: featureQuery.isFetching,
    isFetchingUsage: usageQuery.isFetching,
  };
};
