import { create } from "zustand";
const defaultUserSettings = {
  language: "en",
  dateFormat: "YYYY-MM-DD",
  timeFormat: "HH:mm",
};

interface UserSettings {
  language: string;
  dateFormat: string;
  timeFormat: string;
}

interface UserState {
  userSettings: UserSettings;
  formatComboTime: string;
  setUserSettings: (settings: Partial<UserSettings>) => void;
  updateLanguage: (language: string) => void;
}

export const useUserStore = create<UserState>((set) => ({
  userSettings: defaultUserSettings,
  formatComboTime: `${defaultUserSettings.dateFormat} ${defaultUserSettings.timeFormat}`,
  setUserSettings: (newSettings) =>
    set((state) => {
      const updatedSettings = { ...state.userSettings, ...newSettings };
      return {
        userSettings: updatedSettings,
        formatComboTime: `${updatedSettings.dateFormat} ${updatedSettings.timeFormat}`,
      };
    }),
  updateLanguage: (language) => {
    console.log("[store] updateLanguage", language);
    // if (i18n.language !== language) {
    //   i18n.changeLanguage(language);
    // }
  },
}));
