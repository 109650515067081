import React, { lazy } from "react"; // 移除 Suspense 导入
import { isDevEnv } from "@/environments";
import { Navigate, Route, Routes } from "react-router-dom";
import { Spinner, Page } from "@shopify/polaris"; // 保留 Spinner 导入

// 懒加载页面组件
const ExitFrame = lazy(() => import("@/pages/auth/Exitframe"));
const NotFound = lazy(() => import("@/pages/errors/NotFound"));
const Billing = lazy(() => import("@/pages/billing"));
const ActiveWebhooks = lazy(() => import("@/pages/debug/ActiveWebhooks"));
const RedirectToSwagger = lazy(() => import("@/pages/debug/RedirectToSwagger"));
const Email = lazy(() => import("@/pages/email"));
const EmailContentSetting = lazy(
  () => import("@/pages/email/EmailContentSetting"),
);
const EmailGeneralSettings = lazy(
  () => import("@/pages/email/EmailGeneralSettings"),
);
const Index = lazy(() => import("@/pages/Index"));
const Orders = lazy(() => import("@/pages/orders"));
const Settings = lazy(() => import("@/pages/settings"));
const Templates = lazy(() => import("@/pages/templates"));
const CustomTemplateEditor = lazy(
  () => import("@/pages/templates/CustomTemplatesEditor"),
);
const CustomTemplatesGallery = lazy(
  () => import("./pages/templates/CustomTemplatesGallery"),
);
const Error = lazy(() => import("@/pages/errors/Error"));

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Index />} />
    <Route path="/app/orders" element={<Orders />} />
    <Route path="/app/templates" element={<Templates />}>
      <Route index element={<Navigate to="/app/templates/gallery/custom" />} />
      <Route path="gallery/custom" element={<CustomTemplatesGallery />} />
      <Route path="editor/:id" element={<CustomTemplateEditor />} />
    </Route>
    <Route path="/app/email" element={<Email />}>
      <Route index element={<Navigate to="/app/email/general" />} />
      <Route path="general" element={<EmailGeneralSettings />} />
      <Route path="content" element={<EmailContentSetting />} />
    </Route>
    <Route path="/app/billing" element={<Billing />} />
    <Route path="/app/settings" element={<Settings />} />
    <Route path="/error" element={<Error />} />
    <Route path="/exitframe" element={<ExitFrame />} />
    {isDevEnv && (
      <>
        <Route path="/debug" element={<ActiveWebhooks />} />
        <Route path="/tools/swagger" element={<RedirectToSwagger />} />
      </>
    )}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
